import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import contactService from "../../services/contactService";

const Contact = () => {
    const { t } = useTranslation();

    const initialForm = {
        name: "",
        email: "",
        message: "",
    };

    const [contact, setContact] = useState(initialForm);
    const [submittedFailed, setSubmittedFailed] = useState(false);
    const [submittedSuc, setSubmittedSuc] = useState(false);

    const handleInputChange = event => {
        const { name, value } = event.target;

        setContact({ ...contact, [name]: value });
    };

    const saveData = (e) => {
        e.preventDefault();
        var data = {
            name: contact.name,
            email: contact.email,
            message: contact.message
        };


        contactService.create(data)
            .then(response => {

                // done 

                setContact(initialForm);
                setSubmittedSuc(true)
                setSubmittedFailed(false)

                console.log(response.data);
            })
            .catch(e => {
                setSubmittedFailed(true)
                setSubmittedSuc(false)
                console.log(e);
            });

    }



    return (
        <>



            <section id="contact" className="section">
                <div className="container">
                    <div className="title text-center">
                        <h2>{t('contact.title')}</h2>
                    </div>
                    <div className="contact-form col-lg-7 mx-auto">

                        <form id="contact-us">

                            <div className="form-group">
                                <input type="text" value={contact.name}
                                    onChange={handleInputChange} name="name" className="form-control" placeholder={t('contact.firstname_lastname')} />
                            </div>

                            <div className="form-group">
                                <input type="email" value={contact.email}
                                    onChange={handleInputChange} name="email" className="form-control" placeholder="Email" />
                            </div>

                            <div className="form-group">
                                <textarea value={contact.message}
                                    onChange={handleInputChange} name="message" className="form-control" placeholder="Message" />
                            </div>

                            <div className="actions-bottom text-right">
                                <button onClick={saveData} className="btn btn-custom btn-blue" >{t('contact.send')}</button>
                            </div>


                        </form>

                        {submittedFailed && <div className="alert  alert-danger mt-4 text-center" role="alert">
                            Failed to send email </div>}
                        {submittedSuc && <div className="alert alert-success mt-4 text-center" role="alert">
                            Email sended width Success </div>}





                    </div>
                </div>
                <div className="map"><img src="assets/images/map-img.jpg" alt="icon" /></div>
            </section>

        </>
    )
}

export default Contact
