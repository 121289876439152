import React from 'react'
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import Heroslider from './components/Heroslider'
import Product from './components/Product'
import Avantages from './components/Avantages'
import About from './components/About'
import Howitwork from './components/Howitwork'
import Testimonials from './components/Testimonials'
import Contact from './components/Contact'
import Download from './components/Downloads'
import Footer from './components/Footer'


import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { BrowserRouter as Router } from 'react-router-dom'
import './App.css';
import { FloatingWhatsApp } from 'react-floating-whatsapp'

function App() {

  const { i18n } = useTranslation();
  const [initialized, setInitialized] = useState(false);
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    if (!initialized) {
      changeLanguage(localStorage.getItem("language") || "fr");
      setInitialized(true);
    }
  });

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Router>
        <Sidebar isOpen={!isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <main>
          <Heroslider />
          <Product />
          <Howitwork />
          <Avantages />
          <About />
          <Testimonials />
          <Download />
          <Contact />
          <Footer />
          {/* <FloatingWhatsApp  /> */}
        </main>
      </Router>
    </>
  );
}

export default App;
