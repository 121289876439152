import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll'



export const FooterLogo = styled.div`
display:block;

`;
export const FooterLogoLink = styled(LinkS)`
display:block;
cursor:pointer;

`;

export const FooterContainer = styled.footer` 
display:block; 

`;
export const FooterWrap = styled.div`
display:block; 

`;
export const FooterRow = styled.div` 

`;
export const FooterLeft = styled.div`
display:block; 

`;
export const FooterText = styled.p`
display:block; 

`;
export const FooterRight = styled.div`
display:block; 

`;
export const FooterNewsletter = styled.div`
display:block; 

`;
export const NewsletterTitle = styled.span`
display:block; 

`;
export const FooterLinksWrapper = styled.div`
display:block; 

`;
export const FooterLinkItems = styled.div`
display:block; 

`;
export const FooterNav = styled.ul`
display:block; 

`;
export const FooterNavItem = styled.li`
display:block; 

`;
export const FooterLink = styled.a`
display:block; 
font-size:12px;
font-weight:600;
text-transform:uppercase;
cursor:pointer;
&:hover{
    color:#4eadb9;
    text-decoration:none;
}
`;

export const FooterCoodrone = styled.div`
display:block; 

`;
export const CoodroneItem = styled.div`
display:flex; 
font-family: 'Open Sans', sans-serif;
align-items: center;
margin: 0 0 5px;
`;

export const CordoneIcon = styled.span`
 color:#4eadb9;
 font-size:18px;
 margin: 0 10px 0 0;
 align-self: self-start;

`;
export const CordoneInfos = styled.div`
width:calc(100% - 30px)

`;
export const CordonneTitle = styled.div` 
font-weight:600;
text-transform:uppercase;

`;
export const CordonneText = styled.p`
font-weight:300;
font-size: 12px;
margin:0 0 15px ;

`;

export const CordonneEmail = styled.a`
font-weight:600;
color:#ffffff;
&:hover{
    color:#4eadb9;
}

`;
export const SocialList = styled.ul`
display:flex;

`;
export const SocialItem = styled.li`
margin:5px;

`;
export const SocialItemLink = styled.a`
width:40px;
height:40px;
font-size:20px;
border-radius:50%;
background:#2c717a;
color:#ffffff;
display:inline-block;
line-height:40px;
text-align:center;
&:hover{
    background:#ffffff;
    color:#2c717a;
}

`;



