import React from 'react'
import { useTranslation } from "react-i18next";


const Heroslider = () => {
    const { t } = useTranslation();


    return (
        <>
            <section id="homesilder" className="slider">


                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-sm-12 pr-md-0 slider-text" data-aos="fade-up" data-aos-duration="600"
                            data-aos-delay="0">
                            <h1>{t('slider.title')} <span className="blue">!</span> </h1>
                            <p>{t('slider.text')}</p>
                        </div>

                        <div className="col-lg-7 col-sm-12  slider-image" data-aos="fade-up" data-aos-duration="600"
                            data-aos-delay="150">
                            <img src="./assets/images/slider.png" alt="img" />
                        </div>
                    </div>

                </div>

            </section>
        </>
    )
}

export default Heroslider
