import React from 'react'
import { useTranslation } from "react-i18next";

const Howitwork = () => {
    const { t } = useTranslation();
    return (
        <>
            <section id="comment-ca-marche" className="section">
                <div className="container">
                    <div className="ca-marche-box">
                        <div className="title text-center">
                            <h2>{t('howitwork.title')}</h2>
                        </div>
                        <div className="row">
                            <div className="block-ca-marche">

                                <div className="col-md-5 ca-marche-item" data-aos="fade-up" data-aos-duration="600"
                                    data-aos-delay="0">

                                    <div className=" ca-marche-item-inner">
                                        <span className="ca-marche-item-icon"><img src="./assets/images/icons/change-icon.png"
                                            alt="img" /></span>
                                        <div className="ca-marche-item-text"><span className="ca-marche-nunber">.01</span>{t('howitwork.text-1')}</div>
                                    </div>

                                </div>
                                <div className="col-md-5 ca-marche-item" data-aos="fade-up" data-aos-duration="600"
                                    data-aos-delay="0">

                                    <div className=" ca-marche-item-inner">
                                        <span className="ca-marche-item-icon"><img src="./assets/images/icons/mobile-icon.png"
                                            alt="img" /></span>
                                        <div className="ca-marche-item-text"><span className="ca-marche-nunber">.02</span>
                                            {t('howitwork.text-2')}</div>
                                    </div>

                                </div>
                                <div className="col-md-5 ca-marche-item" data-aos="fade-up" data-aos-duration="600"
                                    data-aos-delay="0">

                                    <div className=" ca-marche-item-inner">
                                        <span className="ca-marche-item-icon"> <img src="./assets/images/icons/wifi-icon.png"
                                            alt="img" /></span>
                                        <div className="ca-marche-item-text"><span className="ca-marche-nunber">.03</span>
                                            {t('howitwork.text-3')}
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-5 ca-marche-item" data-aos="fade-up" data-aos-duration="600"
                                    data-aos-delay="0">

                                    <div className=" ca-marche-item-inner">
                                        <span className="ca-marche-item-icon"><img src="./assets/images/icons/puce-icon.png"
                                            alt="img" /></span>
                                        <div className="ca-marche-item-text"><span className="ca-marche-nunber">.04</span>{t('howitwork.text-4')}
                                        piles.</div>
                                    </div>

                                </div>

                                <div className="ca-marche-center mx-auto"><img src="./assets/images/bouteille-img.png"
                                    alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Howitwork
