import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SidebarWrap,
    SidebarRoute
} from './style'

const Sidebar = ({ isOpen, toggle }) => {
    const { t } = useTranslation();

    return (
        <SidebarContainer isOpen={!isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="homesilder"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80} onClick={toggle}>{t('Nav.home')}</SidebarLink>
                    <SidebarLink to="nos-produits"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80} onClick={toggle}>{t('Nav.our_Concept')}</SidebarLink>
                    <SidebarLink to="les-avantages"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-50} onClick={toggle}>{t('Nav.our_advantages')}</SidebarLink>
                    <SidebarLink to="qui-sommes-nous"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-45} onClick={toggle}>{t('Nav.our_company')}</SidebarLink>
                    <SidebarLink to="contact"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80} onClick={toggle}>{t('Nav.contact')}</SidebarLink>
                </SidebarMenu>
                <SidebarWrap>
                    <SidebarRoute to='/' className="btn btn-dwonload">
                        télécharger l'application
                        <img src="./assets/images/icons//mobile-icon.png" alt="icon" /> </SidebarRoute>
                </SidebarWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
