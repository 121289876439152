import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'

import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

export const SidebarContainer = styled.aside`

position:fixed;
z-index:9999;
width:100%;
height:100%;
background:#000000;
display:grid;
align-items:center; 
left:0;
transition:0.3s ease-in-out;
 opacity:${({ isOpen }) => (isOpen ? '100%' : '0')};
 top:${({ isOpen }) => (isOpen ? '0' : '-200%')};

`;

export const Icon = styled.div`
position:absolute;
top:1.2rem;
right:1.5rem;
background:transparent;
font-size:2rem;
cursor:pointer; 
outline:none;
`;

export const CloseIcon = styled(FaTimes)`
color:#ffffff;
`;
export const SidebarWrapper = styled.div`
color:#ffffff;
`;
export const SidebarMenu = styled.ul`
display:grid;
grid-template-columns:1fr;
grid-template-rows:repeat(6,80px);

@media screen and (max-width:480px){
    grid-template-rows:repeat(6,60px);
}
`;
export const SidebarLink = styled(LinkS)`
color:#99a2a7;
display:flex;
align-items:center;
justify-content:center;
font-size:1.5rem;
text-decoration:none;
list-style:none;
transition: all .2s ease-in-out;
cursor:pointer;
`;

export const SidebarWrap = styled.div`
display:flex;
justify-content:center;
`;

export const SidebarRoute = styled(LinkR)`
font-family: "Titillium Web", sans-serif;
border-radius:50px;
border:1px solid #4eadb9;
text-transform:uppercase;
color:#ffffff;
padding:10px 20px; 
font-weight:600;
font-size:13px;
cursor:pointer;
transition:all 0.2s ease-in-out;
text-decoration:none;
 
 &:hover{
     transition:all 0.2s ease-in-out;
     background:#ffffff; 
     color:#4eadb9;
 }

`;