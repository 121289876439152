import http from "../http-common";


const create = data => {
  return http.post("/providers/create", data);

};


export default {
  create
};
