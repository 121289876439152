import React from 'react';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const Avantages = () => {
    const { t } = useTranslation();
    return (
        <>
            <section id="les-avantages" className="section">
                <div className="container">

                    <div className="les-avantages-box">
                        <div className="title col-lg-5 col-sm-12 mx-auto">
                            <h2>{t('avantages.title')}</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-7 col-sm-12 avantage_infos" data-aos="fade-up" data-aos-duration="600"
                                data-aos-delay="0">

                                <ul className="list-avantages_items">
                                    <li>
                                        <span className="avantage-item--icon"><FontAwesomeIcon icon={faCheck} /></span>
                                        <div className="avantage-item--text">{t('avantages.avantage-1')} </div>
                                    </li>
                                    {/* <li>
                                        <span className="avantage-item--icon"><FontAwesomeIcon icon={faCheck} /></span>
                                        <div className="avantage-item--text">{t('avantages.avantage-2')} </div>
                                    </li> */}
                                    <li>
                                        <span className="avantage-item--icon"><FontAwesomeIcon icon={faCheck} /></span>
                                        <div className="avantage-item--text">{t('avantages.avantage-3')} </div>
                                    </li>
                                    {/* <li>
                                        <span className="avantage-item--icon"><FontAwesomeIcon icon={faCheck} /></span>
                                        <div className="avantage-item--text">{t('avantages.avantage-4')} </div>
                                    </li> */}
                                    <li>
                                        <span className="avantage-item--icon"><FontAwesomeIcon icon={faCheck} /></span>
                                        <div className="avantage-item--text">{t('avantages.avantage-5')} </div>
                                    </li>
                                    <li>
                                        <span className="avantage-item--icon"><FontAwesomeIcon icon={faCheck} /></span>
                                        <div className="avantage-item--text">{t('avantages.avantage-6')} </div>
                                    </li>
                                    <li>
                                        <span className="avantage-item--icon"><FontAwesomeIcon icon={faCheck} /></span>
                                        <div className="avantage-item--text">{t('avantages.avantage-7')} </div>
                                    </li>
                                    <li>
                                        <span className="avantage-item--icon"><FontAwesomeIcon icon={faCheck} /></span>
                                        <div className="avantage-item--text">{t('avantages.avantage-8')} </div>
                                    </li>
                                    <li>
                                        <span className="avantage-item--icon"><FontAwesomeIcon icon={faCheck} /></span>
                                        <div className="avantage-item--text">{t('avantages.avantage-9')} </div>
                                    </li>
                                    <li>
                                        <span className="avantage-item--icon"><FontAwesomeIcon icon={faCheck} /></span>
                                        <div className="avantage-item--text">{t('avantages.avantage-10')} </div>
                                    </li>
                                    <li>
                                        <span className="avantage-item--icon"><FontAwesomeIcon icon={faCheck} /></span>
                                        <div className="avantage-item--text">{t('avantages.avantage-11')} </div>
                                    </li>
                                    <li>
                                        <span className="avantage-item--icon"><FontAwesomeIcon icon={faCheck} /></span>
                                        <div className="avantage-item--text">{t('avantages.avantage-12')} </div>
                                    </li>
                                    <li>
                                        <span className="avantage-item--icon"><FontAwesomeIcon icon={faCheck} /></span>
                                        <div className="avantage-item--text">{t('avantages.avantage-13')} </div>
                                    </li>
                                </ul>

                            </div>
                            <div className="col-lg-5 col-sm-12 avantage_image" data-aos="fade-up" data-aos-duration="600"
                                data-aos-delay="150">

                                <div className="avantage_image_inner ">
                                    <img src="./assets/images/avantage-img.png" alt="img" />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Avantages
