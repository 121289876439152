import React from 'react'
import { useTranslation } from "react-i18next";
import { animateScroll as scroll } from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedinIn, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt, faEnvelope, faPhoneAlt, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import {
    FooterContainer,
    FooterWrap,
    FooterRow,
    FooterLeft,
    FooterText,
    FooterRight,
    FooterNewsletter,
    NewsletterTitle,
    FooterLogo,
    FooterLogoLink,
    FooterCoodrone,
    CoodroneItem,
    CordoneIcon,
    CordoneInfos,
    CordonneTitle,
    CordonneText,
    CordonneEmail,
    SocialList,
    SocialItem,
    SocialItemLink,
    FooterNav,
    FooterNavItem,
    FooterLink
} from './style.js'

const Footer = () => {
    const { t } = useTranslation();
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <>
            <FooterContainer className="footer">
                <FooterWrap className="container">
                    <FooterRow className="row d-flex ">
                        <FooterLeft className=" col-md-4 footer-left">
                            <FooterLogo className="footer-logo">
                                <FooterLogoLink to="/" onClick={toggleHome}>
                                    <img src="./assets/images/logo.png"
                                        alt=" warningaz" />
                                </FooterLogoLink>
                            </FooterLogo>
                            <FooterCoodrone>
                                <CoodroneItem>
                                    <CordoneIcon><FontAwesomeIcon icon={faMapMarkerAlt} /></CordoneIcon>
                                    <CordoneInfos>
                                        <CordonneTitle>Warningas</CordonneTitle>
                                        <CordonneText> {t('footer.adress1')}</CordonneText>
                                        <CordonneTitle> Warningas LTD</CordonneTitle>
                                        <CordonneText> {t('footer.adress2')} </CordonneText>
                                    </CordoneInfos>
                                </CoodroneItem>

                                <CoodroneItem>
                                    <CordoneIcon><FontAwesomeIcon icon={faEnvelope} /></CordoneIcon>
                                    <CordoneInfos>
                                        <CordonneEmail href="mailto:sb@warningas.com">sb@warningas.com</CordonneEmail>
                                    </CordoneInfos>
                                </CoodroneItem>

                                <CoodroneItem>
                                    <CordoneIcon><FontAwesomeIcon icon={faPhoneAlt} /></CordoneIcon>
                                    <CordoneInfos>
                                        <CordonneTitle>+33 7.56.98.71.59   /  + 44 7427 450316 </CordonneTitle>
                                    </CordoneInfos>
                                </CoodroneItem>
                            </FooterCoodrone>
                        </FooterLeft>
                        <FooterRight className=" col-md-4 footer-right">
                            <FooterNewsletter className="newsletter">
                                <NewsletterTitle className="label-newsletter">{t('footer.menuTitle')}</NewsletterTitle>

                                <FooterNav className="nav-footer">
                                    <FooterNavItem><FooterLink data-toggle="modal" data-target="#terms-and-conditions"> <FontAwesomeIcon icon={faChevronRight} className="iconleft" />{t('footer.menu1')} </FooterLink></FooterNavItem>
                                    <FooterNavItem><FooterLink data-toggle="modal" data-target="#cookies"> <FontAwesomeIcon icon={faChevronRight} className="iconleft" />{t('footer.menu2')}</FooterLink></FooterNavItem>
                                    <FooterNavItem><FooterLink data-toggle="modal" data-target="#legal-notice"> <FontAwesomeIcon icon={faChevronRight} className="iconleft" />{t('footer.menu3')}</FooterLink></FooterNavItem>
                                    <FooterNavItem><FooterLink data-toggle="modal" data-target="#protection"> <FontAwesomeIcon icon={faChevronRight} className="iconleft" />{t('footer.menu4')}</FooterLink></FooterNavItem>
                                </FooterNav>
                            </FooterNewsletter>

                        </FooterRight>
                        <FooterRight className=" col-md-4 footer-right">
                            <FooterNewsletter className="newsletter">
                                <NewsletterTitle className="label-newsletter">{t('footer.followTitle')}</NewsletterTitle>
                                <SocialList>
                                    <SocialItem><SocialItemLink target="_blank" href="https://twitter.com/warningaz"><FontAwesomeIcon icon={faTwitter} /></SocialItemLink> </SocialItem>
                                    <SocialItem><SocialItemLink target="_blank" href="https://www.linkedin.com/company/warningas-phone/"><FontAwesomeIcon icon={faLinkedinIn} /></SocialItemLink> </SocialItem>
                                    <SocialItem><SocialItemLink target="_blank" href="https://www.facebook.com/warningasphone"><FontAwesomeIcon icon={faFacebook} /></SocialItemLink> </SocialItem>
                                    <SocialItem><SocialItemLink target="_blank" href="https://www.instagram.com/warningas.phone/"><FontAwesomeIcon icon={faInstagram} /></SocialItemLink> </SocialItem>
                                </SocialList>
                            </FooterNewsletter>

                        </FooterRight>
                    </FooterRow>
                </FooterWrap>





                <div className="modal fade" id="terms-and-conditions" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" >Conditions générales de vente WARNINGAS</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="text-modal-page">
                                    {t('footer.conditions_generales')}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="modal fade" id="cookies" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" >Généralités</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="text-modal-page">
                                    <h6> WARNINGAS,</h6>
                                    <p>  éditeur du site Warningas.com et du site mobile sous la marque WARNINGAS, respecte la vie privée de ses utilisateurs et clients et s'engage à ce que toutes les informations qu'il recueille, en particulier concernant l'utilisation par le client du service de commerce électronique afin d'acheter ou d'obtenir un produit de WARNINGAS, permettant d'identifier ce dernier soient considérées comme des informations confidentielles.</p>

                                    <p> Warningas utilise les informations personnelles qu'il recueille auprès de ses clients pour permettre la fourniture des services qu'il propose. Conformément aux dispositions de la loi du 6 janvier 1978 relative aux fichiers, à l'informatique et aux libertés, les clients de Wineandco.com disposent d'un droit d'accès, de rectification et d'opposition aux données personnelles le concernant. Ce droit s'exerce soit en en modifiant ses informations personnelles en ligne soit auprès de : WARNINGAS.</p>

                                    <p> Si le client a donné son accord lors de son inscription, de sa commande ou, une fois inscrit, en modifiant ses informations personnelles en ligne, ses données personnelles, peuvent également être transmises à des tiers partenaires de WARNINGAS qui pourront lui adresser des messages promotionnels.</p>

                                    <p>Par ailleurs, WARNINGAS pourra divulguer des statistiques globales et des informations démographiques générales relatives au client et à ses activités, qui n'identifient pas les produits ou les sites Web de WARNINGAS ou n'y font pas référence, ou qui n'utilisent ni ne se réfèrent autrement à une marque, à un nom commercial ou un logo de WARNINGAS.</p>

                                    <h6>Politique d'utilisation des cookies</h6>
                                    <p> Lors de la consultation de notre site warningas.com, des cookies sont déposés sur votre ordinateur, votre mobile ou votre tablette.
</p>
                                    <p> Notre site est conçu pour être attentif aux besoins et attentes de nos lecteurs. C'est entre autres pour cela que nous faisons usage de cookies afin par exemple de vous identifier et d'accéder à votre compte. Cette page vous permet de mieux comprendre comment fonctionnent les cookies et comment les paramétrer.</p>


                                    <h6> Définition d'un cookie</h6>
                                    <p> Un cookie est un fichier texte déposé sur votre ordinateur lors de la visite d'un site ou de la consultation d'une publicité. Il a pour but de collecter des informations relatives à votre navigation et de vous adresser des services adaptés à votre terminal (ordinateur, mobile ou tablette). Les cookies sont gérés par votre navigateur internet.
</p>
                                    <h6>Les différents émetteurs</h6>
                                    <p> Les cookies Warningas.com Il s'agit des cookies déposés par Warningas.com sur votre terminal pour répondre à des besoins de navigation, d'optimisation et de personnalisation des services sur notre site. Seul l'émetteur d'un cookie est susceptible de lire ou de modifier des informations qui y sont contenues.</p>


                                    <p>Les cookies que nous émettons sur nos Sites sont utilisés pour reconnaître le terminal de l'Utilisateur lorsqu'il se connecte à l'un de nos Sites afin de :</p>

                                    <p>Optimiser la présentation de nos Sites aux préférences d'affichage de votre terminal (résolution d'affichage, système d'exploitation utilisé, etc.) lors de vos visites selon les matériels et les logiciels de visualisation ou de lecture que comporte votre terminal,
Permettre à l' Utilisateur d'accéder à des espaces réservés et personnels sur nos Sites, tels que son compte personnel sur la base des informations qu'il a transmises au moment de la création de son compte. L' Utilisateur accède par ce bais à des contenus personnalisés ou qui lui sont réservés.</p>

                                    <p>Mémoriser des informations relatives à un formulaire que vous avez rempli sur notre Site (accès à votre compte / vos préférences).Etablir des statistiques et volumes de fréquentation et d'utilisation de nos Sites, Mettre en place des mesures de sécurité, par exemple lorsqu'il est demandé à l' Utilisateur de se connecter après un certain laps de temps.
</p>


                                    <h6>Les cookies tiers</h6>
                                    <p> Il s'agit des cookies déposés par des sociétés tierces (par exemple des partenaires) pour identifier vos centres d'intérêt et éventuellement personnaliser l'offre publicitaire qui vous est adressée sur et en dehors de notre site.</p>

                                    <p>Ils peuvent être déposés quand vous naviguez sur notre site ou lorsque vous cliquez dans les espaces publicitaires de notre site.</p>

                                    <p>Dans le cadre de partenariat, nous veillons à ce que les sociétés partenaires respectent strictement la loi informatique et libertés du 6 janvier 1978 modifiée et s'engagent à mettre en œuvre des mesures appropriées de sécurisation et de protection de la confidentialité des données.</p>

                                    <p>Mesure d'audience (AT Internet) : http://www.atinternet.com/politique-du-respect-de-la-vie-privee/Mesure d'audience (Google Analytics) :
                                    https://tools.google.com/dlpage/gaoptout
                                    Affiliation (Public Idées) : fr.publicidees.com/notre-societe/privacy-policies/ </p>


                                    <h6> Paramétrer votre navigateur internet</h6>
                                    <p>Vous pouvez à tout moment choisir de désactiver ces cookies. Votre navigateur peut également être paramétré pour vous signaler les cookies qui sont déposés dans votre ordinateur et vous demander de les accepter ou pas. Vous pouvez accepter ou refuser les cookies au cas par cas ou bien les refuser systématiquement.</p>

                                    <p>Nous vous rappelons que le paramétrage est susceptible de modifier vos conditions d'accès à nos contenus et services nécessitant l'utilisation de cookies.</p>
                                    <p>Si votre navigateur est configuré de manière à refuser l'ensemble des cookies, vous ne pourrez pas profiter d'une partie de nos services. Afin de gérer les cookies au plus près de vos attentes nous vous invitons à paramétrer votre navigateur en tenant compte de la finalité des cookies.
</p>


                                    <h6>Internet Explorer</h6>
                                    <p> Dans Internet Explorer, cliquez sur le bouton Outils, puis sur Options Internet.
                                    Sous l'onglet Général, sous Historique de navigation, cliquez sur Paramètres.
                                    Cliquez sur le bouton Afficher les fichiers.
</p>

                                    <h6>Firefox</h6>
                                    <p> Allez dans l'onglet Outils du navigateur puis sélectionnez le menu Options
                                    Dans la fenêtre qui s'affiche, choisissez Vie privée et cliquez sur Affichez les cookies
</p>

                                    <h6>Safari</h6>
                                    <p>Dans votre navigateur, choisissez le menu Édition > Préférences.<br />
Cliquez sur Sécurité.<br />
Cliquez sur Afficher les cookies.</p>

                                    <h6>Google Chrome</h6>
                                    <p>Cliquez sur l'icône du menu Outils.<br />
Sélectionnez Options.<br />
Cliquez sur l'onglet Options avancées et accédez à la section Confidentialité.<br />
Cliquez sur le bouton Afficher les cookies.</p>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>




                <div className="modal fade" id="legal-notice" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" >Mentions legales</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="text-modal-page">

                                    <p>  <strong>Dénomination : WARNINGAS</strong><br />
                                        <strong> Siège social :</strong> LTD 207, Régent Street London W1B 3HH UK<br />
                                        <strong>N° immatriculation RCS :</strong> 120 034 72<br />
                                        <strong> Capital social :</strong> 500 000€<br />
                                        <strong> Représentant légal :</strong> Simon Éric Benchetrit
</p><p>

                                        Ce site est hébergé par :<br />
                                                    Nexen Services SAS<br />
                                                    12 rue Moulin des bruyères<br />
                                                    92400 Courbevoie
</p>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>




                <div className="modal fade" id="protection" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" >PROTECTION DES DONNEES PERSONNELLES</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="text-modal-page">
                                    <p>La société Warningas est soucieuse de la protection des données personnelles. Pour cela, elle s’engage à assurer le meilleur niveau de protection à vos données personnelles en conformité avec la loi Informatique et Libertés.
                                    Pour toute information sur la protection des données personnelles, vous pouvez également consulter le site de la Commission Informatique et Liberté www.cnil.fr
               </p>

                                    <h6> <span>1.</span> Identité du responsable du traitement</h6>
                                    <p> Les données personnelles sont collectées par :
                                    Warningas enregistrée au Registre du Commerce et des sociétés en Angleterre, sous le numéro 120 034 72. Dont le siégé social est situé au 207, Régent Street London W1B 3HH UK
 Ci-après dénommée « Warningas »</p>

                                    <p>Notre référent « Informatique et Libertés » est là pour répondre à toutes vos demandes, y compris d’exercice de droits, relatifs à vos données à caractère personnel. Vous pouvez le joindre par mail à
                                    sb@warningas.com
</p>

                                    <h6> <span>2.</span> L’utilisation de vos données</h6>
                                    <strong className="d-block">Gestion de vos commandes</strong>
                                    <p>
                                        Les informations et données vous concernant sont nécessaires à la gestion de votre commande et à nos relations commerciales. Ces informations pourront également être conservées aux fins de preuve dans le respect des obligations légales et réglementaires (paiement, garantie, litige …).
                                        </p>

                                    <strong className="d-block">  Personnalisation de nos services</strong>
                                    <p>  Les informations et données vous concernant nous permettent d’améliorer et personnaliser les services que nous vous proposons notamment sur notre site internet, site mobile et les informations que nous vous adressons.</p>

                                    <strong className="d-block"> Les informations et newsletters Warningas</strong>
                                    <p>  Afin de vous tenir informés de notre actualité et des avantages dont vous pouvez bénéficier, vous pourrez recevoir des offres de notre enseigne par communication électronique (email, sms…) en vous inscrivant aux newsletters.</p>

                                    <p>

                                        Vous pouvez personnaliser les newsletters que vous souhaitez recevoir dans votre compte en vous rendant dans “Mon compte – Mon Profil - Mes abonnements”. Vous avez alors la possibilité de sélectionner parmi les différentes catégories de newsletters celles qui vous intéressent.
                                        Les envois d’offres et d’informations de Warningas seront adaptés à votre sélection. Si vous ne souhaitez pas recevoir d’offres et informations de Warningas, vous pouvez vous désabonner sur votre page “Mon compte – Mon Profil - Mes abonnements”. En fonction des choix émis lors de la création ou consultation de votre compte vous serez donc susceptibles de recevoir des offres de Warningas. </p>


                                    <h6> <span>3.</span> Les Données collectées sur notre site</h6>
                                    <strong className="d-block">Quelles données ?</strong>
                                    <p>
                                        Nous collectons et traitons notamment vos nom, adresse, adresse email, mot de passe, numéro de téléphone, adresse IP, données de connexions et données de navigation, historiques de commandes, produits consultés, incidents de livraisons, réclamations.</p>
                                    <p>  Le caractère obligatoire ou facultatif des données vous est signalé lors de la collecte par un astérisque. Certaines données sont collectées automatiquement du fait de vos actions sur le site.</p>
                                    <p>  Nous effectuons également des mesures d’audience, nous mesurons par exemple le nombre de pages vues, le nombre de visites du site, ainsi que l’activité des visiteurs sur le site et leur fréquence de retour.</p>

                                    <strong className="d-block"> Quand ?</strong>
                                    <p> Nous collectons les informations que vous nous fournissez notamment quand :</p>
                                    <ul>
                                        <li>vous créez votre compte client « Mon compte »</li>
                                        <li>vous effectuez une commande sur notre site</li>
                                        <li>vous naviguez sur notre site et consultez des produits</li>
                                        <li>vous contactez notre Service Client</li>
                                    </ul>
                                    <strong className="d-block">  Destinataires des données </strong>
                                    <p>  Les données collectées sur le site Warningas sont exclusivement destinées à Warningas.</p>
                                    <p>  Elles peuvent être transmises aux sous-traitants – auxquels Warningas fait appel dans le cadre de l’exécution des services et commandes notamment gestion, exécution, traitement et paiement.</p>

                                    <strong className="d-block"> Vos droits au regard de la loi Informatique et Libertés</strong>
                                    <p>    Conformément à la loi Informatique et Libertés du 6 janvier 1978 modifiée par la loi du 6 Août 2004, vous disposez d'un droit d'accès et de rectification et d’opposition aux données personnelles vous concernant. Il vous suffit de nous écrire en ligne sb@warningas.com en nous indiquant vos nom, prénom, e-mail adresse et si possible votre référence client. Conformément à la réglementation en vigueur, votre demande doit être signée et accompagnée de la photocopie d’un titre d’identité portant votre signature et préciser l’adresse à laquelle doit vous parvenir la réponse. Une réponse vous sera alors adressée dans un délai de 2 mois suivant la réception de la demande</p>

                                    <strong className="d-block">  Cookies et ciblage</strong>
                                    <p>  Nos sites et applications sont conçus pour être particulièrement attentif aux besoins et attentes de nos clients. C'est entre autres pour cela que nous faisons usage de cookies ou technologies similaires de traçage afin de vous identifier et personnaliser nos services notamment pour accéder à votre compte, gérer votre panier de commande, mémoriser vos consultations et personnaliser les offres que nous vous proposons et les informations que nous vous adressons. Les cookies vous permettent notamment de bénéficier de nos conseils personnalisés et d'être informé de nos offres promotionnelles.</p>

                                    <p> Vous pouvez à tout moment mettre fin au ciblage en refusant les cookies dans votre navigateur. Nous vous informons que si votre navigateur est configuré de manière à refuser tous cookies, vous ne pourrez pas profiter de fonctions essentielles de notre site, comme par exemple stocker des articles dans votre panier ou recevoir des recommandations personnalisées.</p>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>



            </FooterContainer>


        </>
    )
}

export default Footer
