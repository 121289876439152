import React from 'react';
import { useTranslation } from "react-i18next";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './style.css';

const options = {
    margin: 15,
    responsiveClass: true,
    autoplay: true,
    dots: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,

        }
    },
};

const Testimonials = () => {

    const { t } = useTranslation();

    return (
        <>
            <section id="temoignages" className="section">
                <div className="container">
                    <div className="title text-center">
                        <h2> {t('testimonials.title')}</h2>
                    </div>
                    <div className="row">

                        <div className="list_partners col-md-7 mx-auto">
                            <div className="temoignages-caroussel ">
                                {

                                    (
                                        <OwlCarousel
                                            className="owl-theme"
                                            loop
                                            {...options}

                                        >
                                            <div className="temoignage-item">
                                                <div className="comments-text">
                                                    <p>{t('testimonials.testimonial-1')}</p>
                                                </div>
                                                <div className="coment-avatar"><img src="/assets/images/avatars/1.jpg" alt="img" /></div>
                                            </div>
                                            <div className="temoignage-item">
                                                <div className="comments-text">
                                                    <p>{t('testimonials.testimonial-2')}</p>
                                                </div>
                                                <div className="coment-avatar"><img src="/assets/images/avatars/1.jpg" alt="img" /></div>
                                            </div>
                                            <div className="temoignage-item">
                                                <div className="comments-text">
                                                    <p>{t('testimonials.testimonial-3')}</p>
                                                </div>
                                                <div className="coment-avatar"><img src="/assets/images/avatars/1.jpg" alt="img" /></div>
                                            </div>
                                            <div className="temoignage-item">
                                                <div className="comments-text">
                                                    <p>{t('testimonials.testimonial-4')}</p>
                                                </div>
                                                <div className="coment-avatar"><img src="/assets/images/avatars/1.jpg" alt="img" /></div>
                                            </div>
                                        </OwlCarousel>

                                    )
                                }
                            </div>
                        </div>



                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonials
