import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink, NavLang } from './style'
import { animateScroll as scroll } from 'react-scroll'

import Dropdown from 'react-bootstrap/Dropdown';
import FlagIcon from '../FlagIcon.js'

import { useTranslation } from 'react-i18next';
// import Modal from 'react-modal';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import signupService from "../../services/signupService";
// import { ToastContainer, toast } from 'react-toastify';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '50px',
        width: '670px',
        maxWidth: '90%',
        padding: '20px 40px'
    },
};

// Modal.setAppElement('#yourAppElement');


const Navbar = ({ toggle }) => {

    // 10
    const [countries] = useState([
        { code: 'fr', title: 'FR' },
        { code: 'gb', title: 'EN' },
        { code: 'es', title: 'ES' }
    ]);
    const [toggleContents, setToggleContents] = useState(<> <FlagIcon code="fr" /> FR </>);
    const [selectedCountry, setSelectedCountry] = useState();



    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(() => {

        changeLanguageDropDown(selectedCountry)

    }, [selectedCountry])

    useEffect(() => {
        window.addEventListener('scroll', changeNav)

    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    // select
    const { t, i18n } = useTranslation();

    // const changeLanguage = event => {
    //     let lng = event.target.value;
    //     localStorage.setItem("language", lng);
    //     i18n.changeLanguage(lng);
    // };

    const changeLanguageDropDown = event => {

        let lng = event;
        localStorage.setItem("language", lng);
        i18n.changeLanguage(lng);
    };

    // const changeLanguage = (option) => {
    //     localStorage.setItem('lang', option.target.value);

    // }
    // const lang = localStorage.getItem('lang') || 'fr';

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setIsOpen(false);
    }

    const [show, setShow] = useState(false);

    const [pseudo, setPseudo] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return
        }
        event.preventDefault();
        setValidated(true);
        setIsLoading(true);
        console.log({ form })
        var data = {
            user: {
                u_login: pseudo,
                u_password: password,
                u_telephone: phone,
                u_email: email,
                u_firstname: firstName,
                u_lastname: lastName,
                u_type: 3,
                u_c_id: null
            }
        };
        signupService.create(data)
            .then(response => {
                if (response?.data?.success) {
                    setIsLoading(false);
                    // toast.success('Votre compte a été crée avec succés!');
                    handleClose()
                } else {
                    setIsLoading(false);
                }
            })
            .catch(e => {
                setIsLoading(false);
                console.log(e);
            });
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const term = queryParams.get("signup")
        console.log({ term })
        if (term) {
            handleShow()
        }
    }, [])


    return (
        <>

            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to='/' onClick={toggleHome}>
                        <img src="./assets/images/logo.png" alt=" warningaz" />
                    </NavLogo>

                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="homesilder"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-80}
                            >{t('Nav.home')}</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="nos-produits"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-80}
                            >{t('Nav.our_Concept')}</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="les-avantages"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-50}
                            >{t('Nav.our_advantages')}</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="qui-sommes-nous"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-45}
                            >{t('Nav.our_company')}</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="contact"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-80}
                            >{t('Nav.contact')}</NavLinks>
                        </NavItem>
                    </NavMenu>

                    <NavLang>

                        <Dropdown
                            onSelect={eventKey => {
                                const { code, title } = countries.find(({ code }) => eventKey === code);

                                setSelectedCountry(eventKey);
                                setToggleContents(<><FlagIcon code={code} /> {title}</>);
                            }}
                        >
                            <Dropdown.Toggle variant="secondary" id="dropdown-flags" className="text-left">
                                {toggleContents}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {countries.map(({ code, title }) => (
                                    <Dropdown.Item key={code} eventKey={code}><FlagIcon code={code} />  {title}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>

                    </NavLang>
                    <NavBtn>
                        <NavBtnLink to='/' onClick={handleShow} className="btn btn-become-seller">
                            <span>{t('Nav.becomeSeller')}</span>
                        </NavBtnLink>
                    </NavBtn>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header >
                            <Modal.Title>
                                <NavLogo to='/'>
                                    <img src="./assets/images/dark-logo.png" className='modal-dark-logo' alt=" warningaz" />
                                </NavLogo>
                                <h1 className='modal-title'>{t('Nav.becomeSeller')} <small>!</small></h1>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="custom-signup-modal">
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <Form.Group controlId="lastnameValidation">
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Pseudo"
                                                onChange={(event) =>
                                                    setPseudo(event.target.value.trim())
                                                }
                                                value={pseudo}
                                            />
                                            <img src='./assets/images/icons/user-input-icon.png' alt='user' />
                                            <Form.Control.Feedback type="invalid">
                                                {t('Nav.requiredPseudo')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group controlId="lastnameValidation">
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder={t('Nav.lastName')}
                                                onChange={(event) =>
                                                    setLastName(event.target.value)
                                                }
                                            />
                                            <img src='./assets/images/icons/user-input-icon.png' alt='user' />
                                            <Form.Control.Feedback type="invalid">
                                                {t('Nav.requiredLastName')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group controlId="firstnameValidation">
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder={t('Nav.firstName')}
                                                onChange={(event) =>
                                                    setFirstName(event.target.value)
                                                }
                                            />
                                            <img src='./assets/images/icons/user-input-icon.png' alt='user' />
                                            <Form.Control.Feedback type="invalid">
                                                {t('Nav.requiredFirstName')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-12'>
                                        <Form.Group controlId="emailValidation">
                                            <Form.Control
                                                required
                                                email
                                                type="email"
                                                placeholder="Email"
                                                onChange={(event) =>
                                                    setEmail(event.target.value)
                                                }
                                            />
                                            <img src='./assets/images/icons/email-input-icon.png' className='email-icon' alt='email' />
                                            <Form.Control.Feedback type="invalid">
                                                {t('Nav.validEmail')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-12'>
                                        <Form.Group controlId="phoneValidation">
                                            <Form.Control
                                                required
                                                pattern="[+]{1}[0-9]{11,14}"
                                                type="tel"
                                                placeholder={t('Nav.phone')}
                                                onChange={(event) =>
                                                    setPhone(event.target.value)
                                                }
                                            />
                                            <img src='./assets/images/icons/phone-input-icon.png' className='phone-icon' alt='phone' />
                                            <Form.Control.Feedback type="invalid">
                                                {t('Nav.validPhone')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-12'>
                                        <Form.Group controlId="pwdValidation">
                                            <Form.Control
                                                required
                                                type="password"
                                                placeholder={t('Nav.password')}
                                                onChange={(event) =>
                                                    setPassword(event.target.value)
                                                }
                                            />
                                            <img src='./assets/images/icons/password-input-icon.png' alt='password' />
                                            <Form.Control.Feedback type="invalid">
                                                {t('Nav.requiredPassword')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-12'>
                                        <Form.Group controlId="pwdConfirmationValidation">
                                            <Form.Control
                                                required
                                                type="password"
                                                placeholder={t('Nav.passwordConfirmation')}
                                                onChange={(event) =>
                                                    setPasswordConfirmation(event.target.value)
                                                }
                                            />
                                            <img src='./assets/images/icons/password-input-icon.png' alt='password' />
                                            <Form.Control.Feedback type="invalid">
                                                {t('Nav.requiredPasswordConfirmation')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-12'>
                                        <button type='submit' className='btn sign-up-seller-btn'>
                                            {isLoading ? <div className='spinner-border text-white'></div> : t('Nav.createAccount')}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                    </Modal>
                    {/* <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    /> */}
                    <NavBtn>
                        <NavBtnLink to='/' className="btn btn-dwonload">
                            <span>{t('Nav.downloadApp')}</span>
                            <img src="./assets/images/icons//mobile-icon.png" alt="icon" />
                        </NavBtnLink>
                    </NavBtn>
                </NavbarContainer>
            </Nav>

            {/* <FloatingWhatsApp  /> */}

        </>
    )
}

export default Navbar
