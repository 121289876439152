import React from 'react'
import { useTranslation } from "react-i18next";

const About = () => {
    const { t } = useTranslation();
    return (
        <>
            <section id="qui-sommes-nous" className="section">
                <div className="container">
                    <div className="title text-center">
                        <h2>{t('about.title')}</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-6 qui-somme-image" data-aos="fade-up" data-aos-duration="600" data-aos-delay="0">

                            <div className="qui-somme_image_inner ">
                                <img src="./assets/images/about-img.png" alt="img" />
                            </div>

                        </div>
                        <div className="col-md-6 qui-somme-text" data-aos="fade-up" data-aos-duration="600"
                            data-aos-delay="150">

                            <h3>{t('about.subtitle')}</h3>
                            <h4>{t('about.text-color')} </h4>
                            <p>{t('about.text-1')}</p>
                            <p>{t('about.text-2')}</p>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About
