import http from "../http-common-mail";


const create = data => {
  return http.post("", data);

};


export default {
  create
};
