import React from 'react'
import { useTranslation } from "react-i18next";

const Product = () => {

    const { t } = useTranslation();

    return (
        <>
            <section id="nos-produits" className="section">
                <div className="container">
                    <div className="title col-lg-5 col-sm-12 mx-auto">
                        <h2>{t('product.title')}</h2>
                    </div>
                    <div className="row d-flex ">
                        <div className="col-lg-6 col-sm-12  produit-left" data-aos="fade-up" data-aos-duration="600"
                            data-aos-delay="0">
                            <div className="video-box">
                                <iframe width="795" height="447" src="https://www.youtube.com/embed/g48tzPxKp8g"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 produit_right pl-md-4" data-aos="fade-up" data-aos-duration="600"
                            data-aos-delay="150">
                            <h3>{t('product.subtitle')}</h3>
                            <p>{t('product.text')}</p>
                            <ul className="list-green">
                                <li>{t('product.list-1')} </li>
                                <li>{t('product.list-2')} </li>
                                {/* <li>{t('product.list-3')}</li> */}
                            </ul>

                        </div>
                    </div>


                </div>
            </section>
        </>
    )
}

export default Product
