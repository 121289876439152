import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

export const Nav = styled.nav`
background: ${({ scrollNav }) => (scrollNav ? '#3b3d3e' : 'transparent')};
box-shadow: ${({ scrollNav }) => (scrollNav ? '0px 6px 20px rgba(0, 0, 0, 0.29)' : 'none')};
padding:${({ scrollNav }) => (scrollNav ? '0 ' : '10px 0')};
// margin-top:-80px;
display:flex;
justify-content:center;
align-items:center;
font-size:1rem;
position:fixed;
width:100%;
left:0;
top:0;
z-index:999;
img{
    height: ${({ scrollNav }) => (scrollNav ? '60px' : '85px')};
    transition:all 0.8s ease;
}
@media screen and (max-width:991px){
    transition:all 0.8s ease;  
    img{
        height: ${({ scrollNav }) => (scrollNav ? '60px' : '60px')};
        transition:all 0.8s ease;
    }
}
`;

export const NavbarContainer = styled.div`
display: felx;
justify-content: space-between;
height:80px;
z-index:1;
width:100%;
padding:0 15px;
max-width:1140px;
position:relative;

`;
export const NavLogo = styled(LinkR)`
justify-self:flex-start;
cursor:pointer;
display:flex;
align-items:center;
margin: 0;

`;

export const MobileIcon = styled.div`
display:none;

@media screen and (max-width:991px){
    color:#ffffff;
    display:block;
    position:absolute;
    right:0;
    top:-10px;
    transform:translate(-100%, 60%);
    font-size:1.8rem;
    cursor:pointer;
}
`;

export const NavMenu = styled.ul`
display:flex;
align-items:center;
list-style:none;
text-align:center;
margin :0;


@media screen and (max-width:991px){
    display:none; 
}
`;

export const NavItem = styled.li`
height:80px;

`;

export const NavLinks = styled(LinkS)`

font-family: "Titillium Web", sans-serif;
color:#99a2a7 !important;
display:flex;
font-size:13px;
text-transform:uppercase;
align-items:center;
text-decoration:none;
padding: 0 1rem;
height:100%;
cursor:pointer;
transition:all 0.2s ease-in-out;

&:hover, &.active{
    color:#ffffff !important;
    transition:all 0.2s ease-in-out;
}
@media screen and (max-width:1200px) and (min-width:991px){
    max-width:100px
}
 
`;

export const NavBtn = styled.nav` 
display:flex;
align-items:center; 
 
@media screen and (max-width:991px){
    display:none; 
}
`;

export const NavBtnLink = styled(LinkR)` 
font-family: "Titillium Web", sans-serif;
border-radius:50px;
border:1px solid #4eadb9;
text-transform:uppercase;
color:#ffffff;
padding:10px 20px; 
font-weight:600;
font-size:13px;
cursor:pointer;
transition:all 0.2s ease ;
text-decoration:none;
 
 &:hover{
     transition:all 0.2s ease ;
     background:#ffffff; 
     color:#4eadb9;
 }
`;

export const NavLang = styled.div`
padding:0 15px;
display:flex;
align-items:center;

@media screen and (max-width:991px){
    margin-right:80px;
}
`;