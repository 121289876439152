import React from 'react'
import { useTranslation } from "react-i18next";

const Download = () => {
    const { t } = useTranslation();
    return (
        <>
            <section id="telecharger-app" className="section">
                <div className="container">
                    <div className="telecharger-box">
                        <div className="row">
                            <div className="col-md-6 telecharger-image" data-aos="fade-up" data-aos-duration="600"
                                data-aos-delay="0">
                                <div className="telecharger-image_inner "> <img src="assets/images/app-img.png" alt="img" />
                                </div>

                            </div>
                            <div className="col-md-6 about_update" data-aos="fade-up" data-aos-duration="600"
                                data-aos-delay="150">
                                <div className="title ">
                                    <h2>{t('download.title')}</h2>
                                </div>
                                <p>{t('download.text')} : <a href="http://warningas.com">warningas.com</a></p>
                                <div className="app-dwonload">
                                    <div className="left-app-dwonlod">
                                        <a href='#'  className="btn download-btn"><img src="assets/images/appstor-icon.png"
                                            alt="icon" /></a>
                                        <a href='https://play.google.com/store/apps/details?id=com.warningasphone&pli=1' target='_blank' className="btn download-btn"><img src="assets/images/googleplay-icon.png"
                                            alt="icon" /></a>
                                    </div>
                                    <div className="right-app-dwonlod"><button className="btn"><img src="assets/images/logo-app.png"
                                        alt="icon" /></button></div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Download
